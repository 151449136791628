

































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ManageBuildingInformations extends Vue {
  @Prop() public building!: any;
  public $store: any;
  public $q: any;
  public $apiget: any;
  public informations: any = {};
  public answersCompleted: any = false;
  public questions: any = {};
  public areas: any = [];
  public loading: boolean = true;

  public created() {
    this.refresh();
  }

  @Watch("building")
  private refresh() {
    if (!this.building || !this.building.id) {
      return;
    }
    this.loading = true;
    this.$store
      .dispatch("loadBuildingInformations", {
        buildingId: this.building.id,
        params: { with_areas: true },
      })
      .then((response: any) => {
        this.informations = response.answers;
        this.questions = response.questions;
        this.answersCompleted = response.completed;
        this.areas = response.areas;
        this.loading = false;
      });
  }

  public optionExist(key: any) {
    const value = this.informations[key];
    return value !== undefined && value !== null;
  }

  public getOptionName(key: any) {
    const value = this.informations[key];
    if (value === undefined || value === null) {
      return [];
    }

    const skey = key
      .split(".")
      .slice(1)
      .join(".");
    let questions = this.questions;
    let lastQuestion: any = null;
    let found = false;

    // search the sub question part
    for (const question of questions) {
      if (question.key === skey) {
        lastQuestion = question;
        found = true;
        break;
      }
    }

    if (!found) {
      return [];
    }

    // search the value
    for (const option of lastQuestion.options) {
      if (option.key == value) {
        return option.name;
      }
    }

    return [];
  }
}
