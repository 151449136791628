import { render, staticRenderFns } from "./ManageBuildingInformations.vue?vue&type=template&id=4d219ec0&scoped=true&"
import script from "./ManageBuildingInformations.vue?vue&type=script&lang=ts&"
export * from "./ManageBuildingInformations.vue?vue&type=script&lang=ts&"
import style0 from "./ManageBuildingInformations.vue?vue&type=style&index=0&id=4d219ec0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d219ec0",
  null
  
)

/* custom blocks */
import block0 from "./ManageBuildingInformations.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QIcon,QBtn,QTooltip});
